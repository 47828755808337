import { Component, OnDestroy, OnInit } from '@angular/core';
import { HideNavBarFooterGuard } from './services/guards/navfooter.guard';
import * as $ from 'jquery';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy{
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hideNavbarFooter = false;
  constructor(private hideNavBarFooterGuard: HideNavBarFooterGuard) {
    this.hideNavBarFooterGuard.hideNavBarFooter.subscribe(hideNavbarFooter => {
      this.hideNavbarFooter = hideNavbarFooter;
      const classToAddRemove = "boxed-layout fixed-nav";
      if (this.hideNavbarFooter) {
        $('body').addClass(classToAddRemove);
      } else {
        $('body').removeClass(classToAddRemove);
      }
    });
  }
  
  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
