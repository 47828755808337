import { catchError, firstValueFrom, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HeaderService } from '../header/header.service';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../errorservice/error.service';
@Injectable({
    providedIn: 'root',
})
export class MDashService {
    private url = environment.urlpath;
    private apiVersion = '3.0.10';
    constructor(private http: HttpClient, private headerService: HeaderService, private errorService: ErrorService) { }

    GetMDashTree(){
        return this.http.get(`${this.url}/mdash`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetArticleById(id: string, extraParam?) {
        let Params = new HttpParams();
        Params = Params.append('id', id);
        Params = Params.append('fromtype', '1');
        Params = Params.append('usageRef', '99193');
        if(typeof extraParam === 'string'){
            Params = Params.append('uR', extraParam);
        } else if(typeof extraParam === 'object') {
            if(extraParam.hasOwnProperty('uR')){
                Params = Params.append('uR', extraParam.uR);
            }
            if(extraParam.hasOwnProperty('rName')){
                Params = Params.append('rName', extraParam.rName);
            }
        }
        Params = Params.append('rdm', Date.now().toString());
        return this.http.get(`${this.url}/mdash/story`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetSummarizeArticle(articleId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', articleId.toString());
        return this.http.get(`${this.url}/mdash/summarize`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetGoogleLanguageTranslateList() {
        let Params = new HttpParams();
        Params = Params.append('v', '2');
        return this.http.get(`${this.url}/mdash/language`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetStoryGoogleTranslate(articleId: number, translateTo: string) {
        let Params = new HttpParams();
        Params = Params.append('id', articleId.toString());
        Params = Params.append('translate_to', translateTo);
        return this.http.get(`${this.url}/mdash/translate2`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetMyIntelReportV2(parentId: number, relId: number, imvSelArry: any[], defItem: any) {
        let body = new FormData();
        body.append('parentId', parentId.toString())
        body.append('relationshipId', relId.toString())
        if (imvSelArry) {
            body.append('imvselect', imvSelArry.toString())
        }
        body.append('v', '3')
        body.append('def', JSON.stringify(defItem));
        return this.http.post(`${this.url}/mdash/getMyIntelReport`, body,
            { headers: this.headerService.returnHeader() }).pipe(map((item: any) => {
                item.getMyIntelReport.defItem = defItem;
                return item;
            }), catchError(this.errorService.handleHttpError));
    }
    GetCIOReport(parentId: number, relId: number, frX: { paramKey: string, paramValue: number },
        frXsz: { paramKey: string, paramValue: number }, frXgl: { paramKey: string, paramValue: number }, versionNumber?: number, imvSelectId?: number) {
        let Params = new HttpParams();
        Params = Params.append('parentId', parentId.toString())
        Params = Params.append('relationshipId', relId.toString());
        Params = Params.append('dashboard', '1');
        Params = Params.append(frX.paramKey, frX.paramValue.toString());
        Params = Params.append(frXsz.paramKey, frXsz.paramValue.toString());
        if (frXgl.paramValue) {
            Params = Params.append(frXgl.paramKey, frXgl.paramValue.toString());
        }
        if (versionNumber) {
            Params = Params.append('v', versionNumber.toString());
        }
        if (imvSelectId) {
            Params = Params.append('imvselect', imvSelectId.toString());
        }
        return this.http.get(`${this.url}/mdash/getMyIntelReport`, { params: Params, headers: this.headerService.returnHeader() });
    }
    GetLazyCIOReport(parentId: number, relId: number, frX: { paramKey: string, paramValue: number },
        frXsz: { paramKey: string, paramValue: number }, frXgl: { paramKey: string, paramValue: number }, versionNumber?: number, imvSelectId?: number) {
        let Params = new HttpParams();
        Params = Params.append('lazyload', 'true');
        Params = Params.append('parentId', parentId.toString());
        Params = Params.append('relationshipId', relId.toString());
        Params = Params.append('dashboard', '1');
        Params = Params.append(frX.paramKey, frX.paramValue.toString());
        Params = Params.append(frXsz.paramKey, frXsz.paramValue.toString());
        if (frXgl.paramValue) {
            Params = Params.append(frXgl.paramKey, frXgl.paramValue.toString());
        }
        Params = Params.append('v', versionNumber.toString());
        if (imvSelectId) {
            Params = Params.append('imvselect', imvSelectId.toString());
        }
        return this.http.get(`${this.url}/mdash/getMyIntelReport`, { params: Params, headers: this.headerService.returnHeader() });
    }
    GetStory(storyId: number) {
        let body = new FormData();
        body.append('id', storyId.toString());
        return this.http.post(`${this.url}/mdash/getStoryClob`, body,{ headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetSearchSuggest(queryText: string){
        let body = new FormData();
        body.append('q', queryText)
        return this.http.post(`${this.url}/mdash/searchsuggest?version=${this.apiVersion}`, body, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError))
    }
    GetIndicatorBySeriesId(seriesId: number) {
        return this.http.get(`${this.url}/mdash/getbenchmarkgraph?series=${seriesId}`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetTrendingByStoryData(articleId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', articleId.toString());
        return this.http.get(`${this.url}/mdash/trending`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetStoryRelatedReports(storyId: string){
        let Params = new HttpParams();
        Params = Params.append('id', storyId);
        return this.http.get(`${this.url}/mdash/getRelatedReport`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetBenchMarkById(benchMarkId) {
        return this.http.get(`${this.url}/mdash/getBenchmark?id=${benchMarkId}`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    DeleteBenchMark(benchMarkId) {
        return this.http.get(`${this.url}/mdash/getBenchmark?id=${benchMarkId}`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetBenchMarkExcelById(benchMarkId, range) {
        let startDate = range.startDate;
        let endDate = range.endDate;
        return this.http.get(`${this.url}/mdash/getBenchmark?id=${benchMarkId}&startDate=${startDate}&endDate=${endDate}`, { headers: this.headerService.returnHeader(), responseType: 'blob', observe: 'response' }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetMyShortcutsById(topicId) {
        let Params = new HttpParams();
        Params = Params.append('rdm', Date.now().toString());
        return this.http.get(`${this.url}/mdash/topic2?${topicId}&version=${this.apiVersion}`, {params: Params,headers: this.headerService.returnHeader()}).pipe(catchError(this.errorService.handleHttpError))
    }
    GetChatGPTQuery(configParams:string, articlesArray: any[],subquery?:string) {
        let body = new FormData();
        body.append('configSource', configParams);
        body.append('article', JSON.stringify(articlesArray));
        body.append('rdm', Date.now().toString());
        if(subquery){
            body.append('subquery', subquery);
        }
        return this.http.post(`${this.url}/mdash/chatgpt_req`, body, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError))
    }
    GetMyChatGPT() {
        let Params = new HttpParams();
        Params = Params.append('rdm', Date.now().toString());
        return this.http.get(`${this.url}/mdash/my_chatgpt`, {params: Params,headers: this.headerService.returnHeader()}).pipe(catchError(this.errorService.handleHttpError))
    }
    GetRelatedStoryByMap(articleId: number, divisonId: number){
        let Params = new HttpParams();
        Params = Params.append('id', articleId.toString());
        if(divisonId){
            Params = Params.append('divisionId', divisonId.toString());
        }
        return this.http.get(`${this.url}/mdash/getRelatedStories`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetActivationCode(config){
        let Params = new HttpParams();
        return this.http.get(`${this.url}/mdash/searchconfig2_activation?${config}&maxcountdefault=-1&version=${this.apiVersion}`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    async GetCompBySearchConfig2(config) {
        let Params = new HttpParams();
        Params = Params.append('rdm', Date.now().toString());
        let ReportData: any = await firstValueFrom(this.http.get(`${this.url}/mdash/searchconfig2?${config}&maxcountdefault=-1&version=${this.apiVersion}`,{ params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError)));
        if(ReportData){
            console.log('Check REPORT DATA',ReportData );
            let body = new FormData();
            body.append('aln', ReportData.searchconfig.searchTitle);
            let checkTopic: any = await firstValueFrom(this.http.post(`${this.url}/mdash/checktopic?${ReportData.searchconfig.qrystring}`, body, {  headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError)));
                console.log('Check TOPIC',checkTopic );
            if(checkTopic.hasOwnProperty('checktopic')){
                ReportData.searchconfig.topic = { status: checkTopic.checktopic.status, topicId: checkTopic.checktopic.topicId,
                topicName: checkTopic.checktopic.topicName, topicXML: checkTopic.checktopic.topicXML, teamAlertId: ReportData.searchconfig.topic.teamAlertId
                };
            }
        }
        return ReportData;
    }
    GetSearchConfigI2AI(sconfig: string){
        let Params = new HttpParams();
        Params = Params.append('searchpattern', sconfig);
        Params = Params.append('rdm', Date.now().toString());
        return this.http.get(`${this.url}/mdash/searchconfigi2AI`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetSearchConfigOpenAI(sconfig: string){
        let Params = new HttpParams();
        Params = Params.append('searchpattern', sconfig);
        Params = Params.append('rdm', Date.now().toString());
        return this.http.get(`${this.url}/mdash/searchconfigOpenAI`, { params: Params, headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    GetShareUsers(){
        return this.http.get(`${this.url}/mdash/getShareUsers?version=${this.apiVersion}`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError)); 
    }
    SendShare(articleId: number, sharesubject: string, shareto: Array<number | string>, showallemail: boolean, shareMessage: string){
        let body = new FormData();
        body.append('shareId', articleId.toString());
        body.append('sharesubject', sharesubject);
        body.append('shareto', shareto.join());
        body.append('showallemail', showallemail.toString());
        body.append('version', this.apiVersion);
        body.append('i2connect', 'false');
        body.append('shareMessage', shareMessage?shareMessage:'');
        return this.http.post(`${this.url}/mdash/share`, body, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError))
    }
    SendRequestToAdmin(edashId: string, token: string){
        let body = new FormData();
        body.append('edashId', edashId);
        body.append('token', token);
        return this.http.post(`${this.url}/mdash/register`, body, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError))
    }
    GetAllNewUserRegistrations(){
        return this.http.get(`${this.url}/mdash/new_user_approval`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    NewUserApprovalReject(emails: string, reject: boolean){
        let body = new FormData();
        body.append('email', emails);
        body.append('reject', reject.toString());
        return this.http.post(`${this.url}/mdash/new_user_approval`, body, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError))
    }
}