import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthenticationService, private route: ActivatedRoute) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status === 200 && event.body.hasOwnProperty('authorized') && !event.body.authorized) {
          let dataToPass = null;
          if(this.router.url.includes('/redirect')){
            dataToPass = this.getDataToPass();
          }else if(this.router.url.includes('/config') && event.url.includes('/mdash/getUserGraphInfo')){
            dataToPass = this.getDataToPass();
          }
          console.log('[AUTH INTERCEPTOR]', this.router.url);
          debugger
          if(event.url.includes('/mdash/register')){
            return true;
          }
          if (event.body.UserInDB) {
            // AUTH FALSE AND USER IN DB TRUE
            this.authService.clearAllStorage();
            this.navigateWithData('/notregistered', dataToPass);
            return true;
          } else {
            // AUTH FALSE AND USER IN DB FALSE
            this.authService.clearAllStorage();
            this.navigateWithData('/unauthorized', dataToPass);
            return true;
          }
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // redirect to the login route
            // or show a modal
            this.router.navigate(['/error404']);
          }
        }
      }),
      filter((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.hasOwnProperty('authorized') && !event.url.includes('/mdash/register')) {
            return event.body.authorized;
          } else {
            return true;
          }
        }
        return true;
      })
    );
  }
  private navigateWithData(path: string, dataToPass: any): void {
    if (dataToPass) {
      this.router.navigate([path], { state: { data: dataToPass } });
    } else {
      this.router.navigate([path]);
    }
  }
  private getDataToPass(): { token: string; edashId: string } {
    return {
      token: this.authService.getAssertionToken(),
      edashId: this.authService.getEDashId()
    };
  }
}
