import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HideNavBarFooterGuard } from './services/guards/navfooter.guard';
import { LoginGuard } from './services/guards/login.guard';
const routes: Routes = [
  { path: '', redirectTo: 'config', pathMatch: 'full' },
  { path: 'config', loadChildren: () => import('./config/config.module').then(m => m.ConfigModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': false }},
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true } },
  { path: 'article/:id', loadChildren: () => import('./article/article.module').then(m => m.ArticleModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true }},
  { path: 'indicator', loadChildren: () => import('./indicator/indicator.module').then(m => m.IndicatorModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true }},
  { path: 'searchconfig', loadChildren: () => import('./searchconfig/searchconfig.module').then(m => m.SearchconfigModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true }},
  { path: 'viewsector', loadChildren: () => import('./searchconfig/searchconfig.module').then(m => m.SearchconfigModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true }},
  { path: 'searchreadsaved', loadChildren: () => import('./searchconfig/searchconfig.module').then(m => m.SearchconfigModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true }},
  { path: 'notregistered', loadChildren: () => import('./notregistered/notregistered.module').then(m => m.NotRegisteredModule), canActivate: [HideNavBarFooterGuard], data: { 'hideNavBarFooter': false }},
  { path: 'unauthorized', loadChildren: () => import('./unauthorize/unauthorize.module').then(m => m.UnauthorizeModule), canActivate: [HideNavBarFooterGuard], data: { 'hideNavBarFooter': false }},
  { path: 'adminsetting', loadChildren: () => import('./adminsetting/adminsetting.module').then(m => m.AdminSettingModule), canActivate: [LoginGuard,HideNavBarFooterGuard], data: { 'hideNavBarFooter': true }},
  { path: 'redirect', loadChildren: () => import('./redirect/redirect.module').then(m => m.RedirectModule), canActivate: [HideNavBarFooterGuard], data: { 'hideNavBarFooter': false }},
    // Handle all other routes
  { path: '**', loadChildren: () => import('./error404/error404.module').then(m => m.error404Module), canActivate: [HideNavBarFooterGuard], data: { 'hideNavBarFooter': false }}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [HideNavBarFooterGuard]
})
export class AppRoutingModule { }
