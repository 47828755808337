<div class="border-bottom">
  <nav id="navbarscroll" class="navbar navbar-fixed-top white-bg p-sm" role="navigation" style="margin-bottom: 0">
    <div class="col-md-12">
      <div class="row">
        <!-- ROUTE TO HOME IF IMAGE IS CLICKED -->
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="navbar-header">
            <svg (click)="RouteHome()" version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="225" height="51" viewBox="0 15 1450 330"
            preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0,350) scale(0.1,-0.1)" fill="#58595b" stroke="none">
                <path id="Bulb" fill="#58595b" d="M5010 3020 c-167 -21 -346 -92 -457 -182 -29 -24 -53 -51 -53 -61 0
                -9 15 -26 33 -38 26 -16 45 -19 98 -17 60 3 84 12 255 95 204 99 242 113 319
                113 79 0 289 -56 384 -101 120 -58 241 -179 300 -299 65 -132 86 -291 61 -448
                -27 -165 -98 -299 -292 -547 -171 -220 -269 -392 -328 -580 -17 -55 -36 -111
                -41 -124 l-10 -23 -157 5 c-188 6 -240 18 -262 64 -15 32 -35 43 -78 43 -54 0
                -30 -66 38 -100 73 -37 166 -53 317 -54 79 -1 143 -2 143 -3 0 -1 -5 -25 -11
                -53 l-11 -50 -46 -1 c-102 -3 -200 3 -265 18 -54 12 -71 20 -84 41 -24 39 -43
                52 -75 52 -60 0 -39 -64 32 -100 64 -33 156 -50 298 -57 l133 -6 -3 -46 c-3
                -44 -4 -45 -43 -54 -43 -9 -225 6 -288 24 -27 7 -44 20 -59 44 -32 56 -121 61
                -103 6 22 -70 190 -121 401 -121 l84 0 0 -51 c0 -60 14 -57 33 8 l14 47 74 16
                c128 27 203 78 186 124 -9 24 -36 19 -46 -7 -11 -28 -88 -64 -156 -74 l-46 -6
                7 29 c19 76 21 79 45 82 98 12 205 76 197 118 -5 29 -36 29 -47 1 -9 -23 -71
                -56 -133 -71 -32 -7 -33 -4 -17 60 10 40 15 45 53 55 90 24 157 77 142 114 -9
                23 -30 18 -54 -14 -12 -17 -40 -35 -67 -45 l-46 -15 7 32 c15 70 89 268 138
                372 29 61 97 175 151 255 190 279 207 306 250 395 68 140 89 227 90 375 0 148
                -12 203 -70 325 -97 202 -300 353 -560 416 -94 23 -270 32 -375 19z"/>
                <path id="idot1" fill="#0092cf" d="M4839 2490 c-9 -5 -22 -23 -29 -39 -35 -85 93 -142 135 -60 18 34 13
                61 -18 89 -22 21 -62 26 -88 10z"/>
                <path id="idot2" fill="#2b3d97" d="M5072 2477 c-48 -51 -12 -127 60 -127 69 0 101 77 53 125 -32 33 -83
                34 -113 2z"/>
                <path id="idot3" fill="#0092cf" d="M5328 2470 c-24 -25 -29 -36 -24 -58 15 -61 87 -82 131 -37 33 32 31
                60 -4 96 -37 37 -65 37 -103 -1z"/>
                <path fill="#58595b" d="M1610 2109 l0 -161 -35 31 c-81 71 -227 62 -301 -21 -54 -58 -69
                -107 -69 -218 0 -99 2 -108 31 -160 45 -79 104 -114 193 -114 74 0 99 10 154
                62 l37 36 0 -42 0 -42 46 0 c38 0 45 3 40 16 -3 9 -6 186 -6 395 l0 379 -45 0
                -45 0 0 -161z m-72 -181 c48 -30 72 -86 72 -174 0 -80 -13 -128 -44 -162 -90
                -101 -236 -50 -266 93 -20 98 15 204 82 245 44 27 111 26 156 -2z"/>
                <path fill="#58595b" d="M8230 1875 l0 -395 50 0 50 0 0 395 0 395 -50 0 -50 0 0 -395z"/>
                <path fill="#58595b" d="M8510 1875 l0 -395 45 0 45 0 0 395 0 395 -45 0 -45 0 0 -395z"/>
                <path id="i1" fill="#0092cf" d="M4841 2243 c-21 -18 -21 -21 -19 -358 l3 -339 28 -16 c24 -13 30 -13
                55 0 l27 16 3 339 c2 337 2 340 -19 358 -12 9 -29 17 -39 17 -10 0 -27 -8 -39
                -17z"/>
                <path id="i2" fill="#2b3d97" d="M5080 2240 c-19 -19 -20 -33 -20 -348 0 -316 1 -329 20 -350 26 -28
                71 -28 96 -1 18 20 19 41 19 349 0 308 -1 329 -19 349 -24 27 -70 27 -96 1z"/>
                <path id="i3" fill="#0092cf" d="M5353 2250 c-12 -5 -25 -19 -28 -32 -3 -13 -4 -169 -3 -348 l3 -324
                28 -16 c24 -13 30 -13 55 0 l27 16 3 341 c2 265 -1 343 -10 350 -21 17 -52 22
                -75 13z"/>
                <path fill="#58595b" d="M326 2208 c-21 -30 -20 -54 4 -78 39 -39 100 -14 100 43 0 55 -73 79
                -104 35z"/>
                <path fill="#58595b" d="M6386 2214 c-25 -25 -20 -71 10 -90 55 -37 117 25 80 80 -19 30 -65
                35 -90 10z"/>
                <path fill="#58595b" d="M8784 2204 c-37 -55 25 -117 80 -80 30 19 35 65 10 90 -25 25 -71 20
                -90 -10z"/>
                <path fill="#58595b" d="M12234 2204 c-37 -55 25 -117 80 -80 30 19 35 65 10 90 -25 25 -71
                20 -90 -10z"/>
                <path fill="#58595b" d="M3033 2158 c-31 -10 -32 -12 -35 -77 l-3 -66 -42 -3 c-42 -3 -43 -4
                -43 -38 0 -34 0 -34 45 -34 l45 0 0 -185 c0 -200 7 -238 51 -266 32 -21 76
                -29 127 -22 41 5 42 7 42 42 0 36 0 36 -38 33 -27 -3 -45 2 -62 16 -25 20 -25
                23 -28 201 l-3 181 70 0 71 0 0 35 0 34 -67 3 -68 3 -3 78 c-3 82 -3 83 -59
                65z"/>
                <path fill="#58595b" d="M7343 2159 c-33 -10 -33 -10 -33 -74 l0 -65 -45 0 c-45 0 -45 0 -45
                -34 0 -34 1 -35 43 -38 l42 -3 5 -200 c3 -136 9 -207 18 -222 20 -36 64 -53
                137 -53 l65 0 0 39 0 39 -38 -5 c-28 -4 -44 0 -62 15 -25 20 -25 20 -25 204
                l0 183 68 3 67 3 0 34 0 35 -70 0 -70 0 0 75 c0 81 0 80 -57 64z"/>
                <path fill="#58595b" d="M13902 2139 c-103 -52 -131 -189 -56 -279 92 -109 269 -78 320 55 60
                157 -109 300 -264 224z m164 -34 c79 -51 88 -168 19 -235 -89 -86 -235 -19
                -235 108 0 93 69 157 159 147 18 -2 44 -11 57 -20z"/>
                <path fill="#58595b" d="M13920 1975 c0 -88 1 -95 20 -95 17 0 20 7 20 40 0 32 4 40 19 40 23
                0 37 -16 46 -53 5 -20 13 -27 30 -27 22 0 23 2 13 38 -5 20 -14 42 -19 48 -6
                7 -4 16 6 24 19 16 19 44 -1 64 -10 11 -34 16 -75 16 l-59 0 0 -95z m100 50
                c16 -19 4 -33 -30 -37 -21 -3 -26 2 -28 25 -3 23 0 27 21 27 14 0 30 -7 37
                -15z"/>
                <path fill="#58595b" d="M796 2015 c-21 -7 -56 -31 -77 -51 l-39 -38 0 42 0 42 -40 0 -40 0 0
                -265 0 -265 45 0 45 0 0 158 c0 168 9 223 43 260 66 72 153 68 204 -7 22 -32
                23 -43 23 -222 l0 -189 50 0 50 0 0 181 c0 211 -9 256 -59 309 -54 56 -128 73
                -205 45z"/>
                <path fill="#58595b" d="M2620 2021 c-87 -28 -123 -73 -124 -152 0 -62 30 -101 102 -134 97
                -45 105 -49 125 -77 16 -22 18 -33 11 -60 -18 -64 -103 -82 -200 -42 -33 14
                -35 14 -44 -11 -6 -15 -10 -31 -10 -36 0 -20 76 -41 147 -42 134 -1 208 62
                200 171 -5 69 -37 103 -142 152 -92 42 -115 72 -93 118 20 41 63 57 118 45 25
                -6 52 -13 61 -17 13 -5 19 1 28 25 6 18 10 33 9 34 -32 25 -144 41 -188 26z"/>
                <path fill="#58595b" d="M3534 2011 c-28 -13 -50 -33 -68 -62 l-26 -44 0 53 0 52 -42 0 -43 0
                3 -265 3 -265 43 0 44 0 4 173 c3 152 5 176 24 207 25 44 78 80 115 80 28 0
                29 2 29 45 0 40 -2 45 -22 45 -13 0 -42 -9 -64 -19z"/>
                <path fill="#58595b" d="M6845 2016 c-22 -9 -55 -31 -72 -50 -30 -33 -33 -34 -33 -13 0 54
                -10 67 -51 67 l-39 0 0 -270 0 -270 50 0 50 0 0 178 c0 149 3 182 18 211 49
                97 181 107 229 18 15 -27 19 -63 21 -219 l4 -188 44 0 45 0 -3 208 c-3 235
                -11 264 -78 309 -48 31 -129 39 -185 19z"/>
                <path fill="#58595b" d="M7789 2015 c-54 -17 -109 -69 -141 -134 -18 -38 -22 -63 -23 -141 0
                -88 2 -99 31 -150 47 -83 103 -114 213 -119 47 -2 109 2 138 9 44 9 53 15 53
                33 0 41 -13 50 -56 38 -54 -15 -148 -14 -187 3 -42 17 -83 66 -97 114 -18 65
                -27 61 183 64 l192 3 -2 60 c-3 80 -26 136 -73 179 -61 55 -139 69 -231 41z
                m149 -75 c32 -20 52 -54 66 -112 l6 -28 -149 0 -150 0 8 28 c33 115 132 165
                219 112z"/>
                <path fill="#58595b" d="M9187 2016 c-96 -35 -156 -124 -165 -245 -12 -170 82 -290 227 -291
                60 0 134 29 154 61 28 45 36 0 16 -93 -15 -70 -57 -112 -124 -126 -54 -11 -84
                -9 -158 15 -27 8 -50 13 -51 12 -2 -2 -7 -19 -11 -37 -6 -30 -4 -33 32 -49 55
                -24 203 -24 265 0 55 21 112 80 129 134 8 25 14 137 16 331 l5 292 -41 0 -41
                0 0 -42 0 -42 -32 32 c-62 59 -141 76 -221 48z m165 -82 c14 -8 37 -30 50 -51
                20 -33 23 -51 23 -128 0 -100 -15 -139 -68 -179 -39 -29 -124 -29 -164 0 -44
                32 -76 106 -76 174 2 155 115 244 235 184z"/>
                <path fill="#58595b" d="M9827 2016 c-98 -35 -157 -124 -165 -252 -8 -133 36 -226 131 -270
                42 -19 68 -24 135 -24 46 0 104 5 129 11 43 11 45 13 39 42 -3 16 -6 33 -6 37
                0 4 -17 2 -37 -6 -46 -16 -151 -18 -190 -3 -42 15 -92 68 -103 109 -21 74 -32
                70 181 70 l192 0 -5 73 c-6 82 -31 141 -78 180 -54 46 -148 59 -223 33z m143
                -71 c33 -17 70 -80 70 -120 l0 -25 -146 0 -146 0 7 28 c26 105 129 161 215
                117z"/>
                <path fill="#58595b" d="M10466 2015 c-21 -7 -53 -27 -70 -44 -18 -17 -35 -31 -39 -31 -4 0
                -7 18 -7 40 l0 40 -40 0 -40 0 0 -270 0 -270 50 0 50 0 0 183 c0 164 2 185 20
                215 68 111 212 87 239 -40 6 -27 11 -119 11 -203 l0 -155 45 0 45 0 0 188 c0
                103 -5 204 -10 224 -30 106 -145 162 -254 123z"/>
                <path fill="#58595b" d="M11070 2016 c-131 -35 -200 -131 -200 -276 0 -120 43 -200 132 -244
                43 -22 66 -26 132 -26 45 0 98 5 119 11 36 10 38 12 31 42 -9 42 -8 42 -53 29
                -164 -50 -299 76 -261 245 13 62 57 119 107 139 38 17 121 18 159 4 32 -13 42
                -5 46 35 3 28 0 31 -42 42 -58 15 -114 15 -170 -1z"/>
                <path fill="#58595b" d="M11560 2021 c-123 -40 -183 -132 -184 -281 0 -102 21 -157 81 -211
                57 -51 97 -62 216 -57 122 5 149 16 138 55 -6 22 -13 27 -32 25 -163 -20 -228
                -8 -274 52 -23 31 -48 110 -37 121 3 3 91 5 195 5 l190 0 -6 71 c-8 92 -46
                164 -106 197 -45 26 -138 37 -181 23z m149 -95 c25 -24 51 -83 51 -115 0 -8
                -44 -11 -145 -11 -158 0 -156 -1 -134 57 12 33 56 81 84 94 39 17 114 4 144
                -25z"/>
                <path fill="#58595b" d="M12692 2010 c-23 -10 -56 -34 -72 -52 l-30 -32 0 47 0 47 -42 0 -43
                0 3 -270 3 -270 44 0 45 0 0 163 c0 89 5 178 10 197 5 20 26 51 45 70 32 31
                42 35 90 35 39 0 59 -5 75 -20 44 -39 50 -73 50 -265 l0 -180 51 0 51 0 -4
                203 c-3 183 -5 206 -25 242 -26 49 -48 70 -96 90 -49 20 -103 19 -155 -5z"/>
                <path fill="#58595b" d="M13317 2019 c-135 -32 -210 -132 -210 -279 0 -174 94 -270 267 -270
                43 0 96 5 117 12 35 10 38 14 33 37 -10 40 -16 42 -71 30 -105 -24 -193 15
                -234 106 -44 97 -6 226 81 273 38 20 55 23 110 19 36 -2 72 -7 81 -11 13 -5
                19 1 28 25 6 17 9 35 6 39 -7 11 -98 30 -138 29 -18 -1 -49 -5 -70 -10z"/>
                <path fill="#58595b" d="M6380 1750 l0 -270 50 0 50 0 0 270 0 270 -50 0 -50 0 0 -270z"/>
                <path fill="#58595b" d="M8790 1750 l0 -270 45 0 45 0 0 270 0 270 -45 0 -45 0 0 -270z"/>
                <path fill="#58595b" d="M12240 1750 l0 -270 45 0 45 0 0 270 0 270 -45 0 -45 0 0 -270z"/>
                <path fill="#58595b" d="M320 1745 l0 -265 50 0 50 0 0 265 0 265 -50 0 -50 0 0 -265z"/>
                <path fill="#58595b" d="M1882 1808 c3 -189 5 -206 26 -250 14 -29 38 -56 60 -70 75 -46 214
                -20 259 49 l18 27 5 -39 c5 -39 6 -40 49 -43 l44 -3 -6 68 c-4 37 -7 157 -7
                266 l0 197 -45 0 -45 0 0 -176 c0 -196 -7 -226 -64 -267 -41 -29 -115 -30
                -146 -2 -48 43 -53 70 -58 263 l-4 182 -45 0 -44 0 3 -202z"/>
                <path fill="#58595b" d="M3700 2005 c0 -3 45 -116 100 -251 55 -136 100 -254 100 -264 0 -43
                -92 -150 -148 -171 -21 -8 -24 -14 -18 -41 3 -18 11 -37 16 -42 15 -15 87 23
                135 70 59 60 103 149 215 442 l99 257 -46 3 c-25 2 -49 0 -53 -5 -4 -4 -38
                -100 -75 -212 l-67 -203 -74 202 c-41 111 -76 206 -79 211 -6 9 -105 13 -105
                4z"/>
                <path fill="#58595b" d="M13640 1580 c-27 -27 -25 -66 6 -96 43 -44 104 -14 104 51 0 59 -68
                87 -110 45z"/>
              </g>
            </svg>
          </div>
        </div>
        <div class="col-md-5 col-sm-5 col-xs-6 autocomp-adj" style="margin-top: 10px; text-align: center;">
          <p-autoComplete type="search" *ngIf="userInfo" [delay]="500" [showClear]="false" [minLength]="2" styleClass="topnav-autocomplete" panelStyleClass="topnav-overlay" inputStyleClass="topnav-input" placeholder="&#xF002; Search..." [(ngModel)]="searchText"
             [suggestions]="SearchSuggestData"  field="value" (completeMethod)="searchSuggest($event.query)" (keyup.enter)="selectedSearchEntered(searchText)" (onSelect)="selectedSearch($event)">
            <ng-template let-rowData pTemplate="item">
              <div class="valuesuggest-items">
                {{rowData.value}}
                <span [ngSwitch]="rowData.type">
                  <span class="type-item" *ngSwitchCase="'Search Terms'"><span class="type-item-emoji">🔎</span>
                  [{{rowData.type}}]</span>
                  <span class="type-item" *ngSwitchCase="'Keyword'"><span class="type-item-emoji">🔑</span>
                    [{{rowData.type}}]</span>
                  <span class="type-item" *ngSwitchCase="'Company Brands'"><span
                      class="type-item-emoji">🏷️</span> [{{rowData.type}}]</span>
                  <span class="type-item" *ngSwitchCase="'Company'"><span class="type-item-emoji">🏢</span>
                    [{{rowData.type}}]</span>
                  <span class="type-item" *ngSwitchCase="'Secondary Company'"><span class="type-item-emoji">🏢</span>
                    [{{rowData.type}}]</span>
                  <span class="type-item" *ngSwitchCase="'Geography'"><span class="type-item-emoji">🌏</span>
                    [{{rowData.type}}]</span>
                  <span class="type-item" *ngSwitchCase="'State'"><span class="type-item-emoji">🌎</span>
                    [{{rowData.type}}]</span>
                    <span class="type-item" *ngSwitchDefault><span class="type-item-emoji">📍</span> [{{rowData.type}}]</span>
                </span>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-6 text-right name-logo">
          <ul class="nav navbar-top-links navbar-right pos-relative">
            <!-- MAIN DROPDOWN MENU -->
            <li class="pos-relative">
              <a *ngIf="userInfo" data-toggle="dropdown" class="dropdown-toggle avatar" href="#">
                <ngx-avatars class="adj-avatar" [textSizeRatio]="2.5" [name]="userInfo.Profile.givenName" [src]="userInfo.Photo" size="47" [initialsSize]="2" [round]="true" borderColor="#ccc" bgColor="#1dcaff"></ngx-avatars>
              </a>
              <ul class="dropdown-menu animated fadeIn m-t-xs pos-absolute adj-pad">
                <li *ngIf="isLicenseAdmin">
                  <a (click)="GoToAdmin()"><i class="fa fa-gear"></i> Admin</a>
                </li>
                <li *ngIf="isLicenseAdmin" class="divider"></li>
                <li>
                  <a *ngIf="isIframe" (click)="PopupLogout()"><i class="fa fa-sign-out"></i> Logout</a>
                  <a *ngIf="!isIframe" (click)="RedirectLogout()"><i class="fa fa-sign-out"></i> Logout</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</div>