export const environment = {
  mode: 'prod',
  urlpath: 'https://www.industryintel.com/api',
  verWeb: require('../../package.json').version,
  locale: 'en',
  production: true,
  msAppId: 'e57b1515-b28b-45b8-8d0d-2a37dc2d3f00',
  msClientId: '824d7d5b-330d-4d86-87a2-13a529d1a5e8', // Application (client) ID from the app registration
  msAuthority: 'https://login.microsoftonline.com/organizations',
  configScope:"api://824d7d5b-330d-4d86-87a2-13a529d1a5e8/access-as-user"
};