import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
console.log(`IS PRODUCTION MODE?: ${environment.production} - [${environment.verWeb}]`);
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';
import { AuthInterceptor } from './services/interceptors/auth.interceptor';
import { FooterModule } from './footer/footer.module';
import { TopNavbarModule } from './topnavbar/topnavbar.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
const redirectURI = 'https://edash.industryintel.com';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
import { init,createErrorHandler, BrowserTracing, Replay, routingInstrumentation } from '@sentry/angular-ivy';
init({
  dsn: "https://7e918228c3e34358abf855d0c5136678@o262274.ingest.sentry.io/6142321",
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new BrowserTracing({
      tracePropagationTargets: ["localhost", environment.urlpath],
      routingInstrumentation: routingInstrumentation,
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    new Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FooterModule,
    TopNavbarModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.msClientId,
          authority: environment.msAuthority,
          redirectUri: redirectURI, // This is your redirect URI
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE,
        },
        system: {
          allowRedirectInIframe: true
        }
      }),null,null),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    }
  ],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
