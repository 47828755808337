import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router, private route: ActivatedRoute) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>(async (resolve) => {
      const assertionToken = this.authService.getAssertionToken();
      const userInfo = this.authService.GetUserInfoFromLStorage();
      const EDashId = this.authService.getEDashId(); 
      const ProductAdmin = this.authService.isLicenseAdmin(); 
      console.log('[LOGIN GUARD] - URL and CONTEXT', state.url, userInfo);
      const isConfigURL = state.url.includes('/config');
      const isAdminSettingURL = state.url.includes('/adminsetting');
      if (isAdminSettingURL && !ProductAdmin) {
        resolve(false);
        this.router.navigate(['/error404']);
        return;
      }
      if (isConfigURL || isAdminSettingURL) {
        const edashTokenId = next.queryParamMap.get('edashId') || null;
        if (EDashId === null && edashTokenId) {
          localStorage.setItem('EDashId', edashTokenId);
        } else if ((EDashId && edashTokenId) && EDashId !== edashTokenId) {
          this.authService.clearAllStorage();
          this.router.navigate(['/config'], { queryParams: { edashId: edashTokenId } });
          resolve(false);
          return;
        }
      }

      if (assertionToken && userInfo && isConfigURL && EDashId) {
        resolve(false);
        this.router.navigate(['/home']);
      } else if (!assertionToken && !isConfigURL) {
        if (state.url.includes('/searchconfig') || state.url.includes('/article')) {
          localStorage.setItem('reroute', state.url);
        }
        resolve(false);
        if(isAdminSettingURL){
          const edashTokenId = next.queryParamMap.get('edashId') || null;
          this.router.navigate(['/config'], { queryParams: { edashId: edashTokenId } });
        }else{
          this.router.navigate(['/config']);
        }
      } else {
        if (state.url.includes('/aichat') && !(this.authService.isITAccess() || this.authService.isSalesFullAccess())) {
          resolve(false);
          this.router.navigate(['/error404']);
        } else {
          resolve(true);
        }
      }
    });
  }
}
