import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../errorservice/error.service';
import { HeaderService } from '../header/header.service';
import { AccountInfo, EndSessionPopupRequest, EndSessionRequest } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
interface UserInfo {Photo: string, Profile: {"@odata.context":string,businessPhones:any[],displayName: string, givenName: string, id: string, jobTitle: any,mail: string,mobilePhone:any,officeLocation:any,preferredLanguage:string,surname: string,userPrincipalName:string}}
@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private url = environment.urlpath;
    constructor(private http: HttpClient, private headerService: HeaderService, private errorService: ErrorService, private msalService: MsalService, private router: Router) { }
    GetTeamConfigMaps(){
        return this.http.get(`${this.url}/mdash/team_config`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    SaveTeamConfigMaps(actv_code:string,map:{id: number, name: string}, tabName: string){
        let body = new FormData();
        if(actv_code){
            body.append('actv_code', actv_code);
        }else{
            body.append('licenseId', map.id.toString());
        }
        body.append('tabName', tabName);
        return this.http.post(`${this.url}/mdash/team_config`, body,{ headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    DeleteTeamConfigMaps() {
        const httpOptions = {
            headers: this.headerService.returnHeader(),
            body: null
        };
        return this.http.delete(`${this.url}/mdash/team_config`, httpOptions).pipe(catchError(this.errorService.handleHttpError));
    }
    getAssertionToken(): string | null {
        const token = localStorage.getItem('AssersionToken');
        return token?token:null;
    }
    getEDashId(): string | null {
        const token = localStorage.getItem('EDashId');
        return token?token:null;
    }
    MdashLogOut(){
        return this.http.get(`${this.url}/mdash/logout`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
    PopupLogout(){
        // Add log out function here
        const logoutRequest: EndSessionPopupRequest = {
            postLogoutRedirectUri: this.GetEdashIdPopupURL()
        }
        this.msalService.logoutPopup(logoutRequest).subscribe((success)=>{
            this.MdashLogOut();
            this.router.navigateByUrl(this.GetEdashIdPopupURL());
            this.clearAllStorage();
        }); 
    }
    RedirectLogout(){
        // Add log out function here
        const logoutRequest: EndSessionRequest = {
          postLogoutRedirectUri: this.GetEdashIdRedirectURL()
        }
        const edashId = this.getEDashId();
        let redirectString = `/config`
        if(edashId){
            redirectString = `${redirectString}?edashId=${edashId}`
            this.MdashLogOut();
            this.router.navigateByUrl(redirectString);
            this.clearAllStorage();
        }else{
            this.msalService.logoutRedirect(logoutRequest);
        }
    }
    GetEdashIdPopupURL():string{
        const edashId = this.getEDashId();
        let redirectString = `/config`
        if(edashId){
            redirectString = `${redirectString}?edashId=${edashId}`
        }
        return redirectString;
    }
    GetEdashIdRedirectURL():string{
        const edashId = this.getEDashId();
        let redirectString = `/redirect?logoutconfigredirect=true`
        if(edashId){
            redirectString = `${redirectString}&edashId=${edashId}`
        }
        return redirectString;
    }
    clearAllStorage() {
        // localStorage.clear();
        this.ClearCacheSilently();
        localStorage.removeItem('ContextData');
        localStorage.removeItem('EDashId');
        localStorage.removeItem('AssersionToken');
        localStorage.removeItem('AssersionTokenData');
        localStorage.removeItem('UserInfo');
        localStorage.removeItem('UserCertInfo');
        localStorage.removeItem('relid');
        localStorage.removeItem('selimv');
    }
    ClearCacheSilently() {
        const account: AccountInfo | undefined = this.msalService.instance.getAllAccounts()[0];
        if (account) {
          // Get all cache keys
          const cacheKeys: string[] = Object.keys(localStorage);
      
          // Filter cache keys associated with the account
          const accountKeys: string[] = cacheKeys.filter(key => key.includes(account.homeAccountId));
      
          // Remove cache entries associated with the account
          accountKeys.forEach(key => localStorage.removeItem(key));
        }
      }
    getReRouteURL(): string | null {
      const reRouteURL = localStorage.getItem('reroute');
      return reRouteURL?reRouteURL:null;
    }
    clearSessionRerouteKey(){
      localStorage.removeItem('reroute');
    }
    GetUserInfoFromLStorage():UserInfo{
        const userInfo = JSON.parse(localStorage.getItem('UserInfo'));
		if(userInfo && userInfo.hasOwnProperty('Profile')){
            return userInfo;
        } else {
            return null;
        }
    }
    isLicenseAdmin(): boolean {
        const UserInfo = JSON.parse(localStorage.getItem('UserInfo'));
        return UserInfo && UserInfo.Profile && UserInfo.Profile.hasOwnProperty('productAdmin')?true:false; 
    }
    GetUserCertInfoFromLStorage(){
        const userCertInfo = JSON.parse(localStorage.getItem('UserCertInfo'));
		if(userCertInfo && userCertInfo.hasOwnProperty('Info')){
            return userCertInfo.Info.qUser[0];
        } else {
            return null;
        }
    }
    GetFullUserCertInfoFromLStorage(){
        const userCertInfo = JSON.parse(localStorage.getItem('UserCertInfo'));
		return userCertInfo?userCertInfo:null;
    }
    isITAccess(): boolean {
      const UserCertInfo = JSON.parse(localStorage.getItem('UserCertInfo'));
      return UserCertInfo && UserCertInfo.qUser[0].company_id === 7 && UserCertInfo.roles.includes('IT')?true:false;
    }
    isSalesFullAccess(): boolean {
      const UserCertInfo = JSON.parse(localStorage.getItem('UserCertInfo'));
      return UserCertInfo && UserCertInfo.roles.includes('Salesman~FullAccess')?true:false;
    }
    GetUserGraphInfo(){
        return this.http.get(`${this.url}/mdash/getUserGraphInfo`, { headers: this.headerService.returnHeader() }).pipe(catchError(this.errorService.handleHttpError));
    }
}
