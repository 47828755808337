import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
    providedIn: 'root',
})
export class HeaderService {
    constructor() { }
    returnHeader(): HttpHeaders {
        let _headers = new HttpHeaders();
        _headers = _headers.append('EDashId', `${this.getEDashIdForHeader()}`);
        _headers = _headers.append('Authorization', `Bearer ${this.getAssertionTokenForHeader()}`);
        _headers = _headers.append('InsideTeams', '0');
        return _headers;
    }

    getAssertionTokenForHeader(): string | null {
        const token = localStorage.getItem('AssersionToken');
        return token?token:null;
    }
    
    getEDashIdForHeader(): string | null {
        const token = localStorage.getItem('EDashId');
        return token?token:null;
    }

}